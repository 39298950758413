export default {
  hello: { en: "Hello", tr: "Merhaba" },
  "TECHNICAL SPECIFICATIONS": {
    en: "TECHNICAL SPECIFICATIONS",
    tr: "TEKNIK DETAYLAR",
  },
  SUMMARY: {
    en: "SUMMARY",
    tr: "ÖZET",
  },
  BUILDS: {
    en: "BUILDS",
    tr: "Konfigurasyonlar",
  },
  SERVICE: {
    en: "SERVICE",
    tr: "SERViS",
  },
  SELECT_INFO: {
    en: "*Manufacturer’s Suggested Retail Price (MSRP), excludes options, taxes, title and registration processing.",
    tr: "",
  },
  Length: {
    en: "Length",
    tr: "Boy",
  },
  Beam: {
    en: "Beam",
    tr: "Genişlik",
  },
  "Beam with open Side Teracces": {
    en: "Beam with open Side Teracces",
    tr: "Açık teras genişliği",
  },
  Draft: {
    en: "Draft",
    tr: "Draft",
  },
  Displacement: {
    en: "Displacement",
    tr: "Displacement",
  },
  Passengers: {
    en: "Passengers",
    tr: "Kapasite",
  },
  Berths: {
    en: "Berths",
    tr: "Berth",
  },
  "Fuel Cap": {
    en: "Fuel Cap.",
    tr: "Yakıt kap.",
  },
  Range: {
    en: "Range",
    tr: "Menzil",
  },
  "Fresh Water Cap": {
    en: "Fresh Water Cap",
    tr: "Su kapasitesi",
  },
  Classification: {
    en: "Classification",
    tr: "Sınıf",
  },
  "ENGINE TYPES": {
    en: "ENGINE TYPES",
    tr: "MOTOR SEÇENEKLERİ",
  },
  "Engine Type": {
    en: "Engine Type",
    tr: "Motor Tipi",
  },
  Propulsion: {
    en: "Propulsion",
    tr: "İtme",
  },
  "Fuel Type": {
    en: "Fuel Type",
    tr: "Yakıt Tipi",
  },
  Engines: {
    en: "Engines",
    tr: "Motorlar",
  },
  Speed: {
    en: "Speed",
    tr: "Hız",
  },
  "EXTRA INCLUDED": {
    en: "EXTRA INCLUDED",
    tr: "EKSTRALAR",
  },
  "STANDARD FEATURES": {
    en: "STANDARD FEATURES",
    tr: "STANDART ÖZELLİKLER",
  },
  "STANDARD SPECIFICATIONS": {
    en: "STANDARD SPECIFICATIONS",
    tr: "STANDARD SPESİFİKASYONLAR",
  },
  STANDARD_INFO: {
    en: `Specifications are based on available information at the time. Actual specifications may vary. [name] reserves the right to change product line specifications, equipment, color scheme and pricing, or to discontinue models at any time without notice or obligation.`,
    tr: `Spesifikasyonlar, o sırada mevcut olan bilgilere dayanmaktadır. Özellikler değişebilir. [name] herhangi bir zamanda bildirimde bulunmaksızın veya herhangi bir yükümlülük altına girmeksizin ürün hattı özelliklerini, ekipmanı, renk şemasını ve fiyatlandırmayı değiştirme veya modelleri durdurma hakkını saklı tutar.`,
  },
  "PURCHASE PRICE": {
    en: "PURCHASE PRICE",
    tr: "SATIŞ FİYATI",
  },
  PRICE: {
    en: "OPTIONS PRICE",
    tr: "OPSİYONLARIN TOPLAM FİYATI",
  },
  Power: {
    en: "Power",
    tr: "Motor Gücü",
  },
  SELECT_YOUR: {
    en: "[name] Configurator",
    tr: "[name] Konfigurator",
  },
  "Transportation Fee": {
    en: "TRANSPORTATION / HANDLING / DELIVERY COSTS",
    tr: "NAKLİYE / TAŞIMA / TESLİMAT MALİYETLERİ",
  },

  "Total Price": {
    en: "Total Price",
    tr: "Toplam Fiyat",
  },

  TRANSPORTATION_INFO: {
    en: `
<b>*Istanbul Kalamış Marina Turnkey Delivery Service</b>
<br /></br />
This service covers the transportation process from the shipyard in Poland to Istanbul, as well as all critical procedures carried out before delivery.
<br /><br />
Service Scope:

<br /><br />
Transport Preparation & Shipping: Cradle and shrink wrap protection, crane rental, labor costs<br />
Customs & Permits: Road tolls, special road permits between Poland and Turkey, export expenses<br />
Freight Insurance & Customs Clearance<br /><br />
Pre-Delivery Process in Istanbul:

<br /><br />
One week of quality control and installation of additional options.<br />
Three days of sea trials with marina accommodation services.<br />
Machine start-up and launching (lift fees included).<br />
Commissioning of Simrad & Mastervolt systems.<br />
Fuel for sea trials (100L) and test run expenses.<br />
Every detail is meticulously managed for a seamless and hassle-free delivery process.<br />
`,
    tr: `
<b>*İstanbul Kalamış Marina Anahtar Teslim Hizmeti</b>
<br /><br />
Bu hizmet, Polonya’daki tersaneden İstanbul’a kadar olan nakliye sürecini ve teslimat öncesi gerçekleştirilen tüm önemli işlemleri kapsamaktadır.<br /><br />

Süreç, nakliye hazırlığı ve yükleme aşamasıyla başlar. Teknenin güvenli taşınmasını sağlamak için cradle ve shrink wrap gibi koruyucu önlemler uygulanır. Nakliye sırasında vinç kiralama ve işçilik maliyetleri dikkate alınarak, taşıma süreci eksiksiz bir şekilde yönetilir. Ayrıca, öngörülemeyen yol harçları, Polonya-Türkiye arasında özel yol izinleri ve ihracat masrafları da hizmet kapsamına dahildir. Nakliyat sigortası ve gümrük işlemleri ise sürecin ayrılmaz bir parçasıdır.<br /><br />

İstanbul’a varışın ardından, tekne 1 hafta boyunca kalite kontrol ve ek opsiyonların montajı için hazırlanır. Ardından, 3 gün süren deniz testleri gerçekleştirilir ve bu süreç boyunca marina konaklama hizmetleri sağlanır. Ayrıca, makine start-up işlemleri ve denize indirme lift ücretleri bu hizmete dahildir. Simrad ve Mastervolt gibi elektronik sistemlerin devreye alınması sağlanırken, deniz testleri için gerekli yakıt (100lt) ve test sürüşü masrafları da karşılanarak kapsamlı bir teslimat süreci sunulmaktadır.
`,
  },

  From: {
    en: "from",
    tr: "başlangıç fiyatı",
  },
  "CONFIGURE YOUR": {
    en: "CONFIGURE YOUR [name]",
    tr: "[name] OLUŞTURUN",
  },
  "ORDER SUMMARY": {
    en: "ORDER SUMMARY",
    tr: "SİPARİŞ ÖZETİ",
  },
  "SEND PDF": {
    en: "SEND PDF",
    tr: "PDF GÖNDER",
  },
  "ENTER YOUR DETAILS": {
    en: "ENTER YOUR DETAILS",
    tr: "KİŞİSEL BİLGİLER",
  },
  SUMMARY_INFO: {
    en: "*Purchase Price including selected options & delivery but excludes taxes, title and registration processing.",
    tr: "*Satış fiyatı seçilenleri ve transferi içerir. Vergiler ve kayıt ücretleri dahil değildir.",
  },

  next: { en: "NEXT", tr: "İLERİ" },
  prev: { en: "BACK", tr: "GERİ" },
  button_pdf: { en: "SEND ME PDF", tr: "PDF GÖNDER" },
  button_buy: { en: "BUY NOW", tr: "HEMEN SATIN AL" },
  footer_purchase_price: {
    en: "Options Price",
    tr: "Opsiyonların Toplam Fiyatı",
  },
  footer_estimate_payment: { en: "Estimate Payment", tr: "Tahmini Ödeme" },
  footer_estimate_delivery: {
    en: "Estimate Delivery<br />6-8 Weeks",
    tr: "Tahmini Teslim Süresi<br />6-8 Hafta",
  },
  summary_title: {
    en: "Your [model] is ready to go!",
    tr: "[model] hazır!",
  },
  summary_standards: {
    en: "STANDARDS",
    tr: "STANDART DONANIM",
  },
  summary_upgrade: {
    en: "UPGRADES & OPTIONS",
    tr: "EKLENTİLER VE SEÇENEKLER",
  },

  form_details: {
    en: "My Details",
    tr: "Kişisel Bilgilerim",
  },
  form_first_name: {
    en: "First Name",
    tr: "İsim",
  },
  form_last_name: {
    en: "Last Name",
    tr: "Soyisim",
  },
  form_email: {
    en: "Email Address",
    tr: "E-Posta Adresi",
  },
  form_phone: {
    en: "Phone Number",
    tr: "Telefon Numarası",
  },
  form_boating: {
    en: "Where are you boating?",
    tr: "Seyir yaptığınız yer?",
  },
};
